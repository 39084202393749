import React from 'react';
import PropTypes from 'prop-types';
import PinterestSingle from './pinterest-single';
import PinterestCarouselAd from './pinterest-carousel';
import './../styles/main.scss';

class PreviewPinterest extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        title: PropTypes.string,
        description: PropTypes.string
    };

    static defaultProps = {
        adType: 'imageAd',
        cta: 'OPEN_LINK',
        advertiser: {
            name: 'Cape',
            logo: ''
        }
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'pin':
                component = <PinterestSingle {...props} />;
                break;

            case 'sponsoredPin':
                component = <PinterestSingle {...props} />;
                break;

            case 'carouselAd':
                component = <PinterestCarouselAd {...props} />;
                break;

            default:
                component = null;
        }
        return component;
    }

    render() {
        const { adType } = this.props;

        return <div className="social-preview__pinterest">{PreviewPinterest.getChild(adType, this.props)}</div>;
    }
}

export default PreviewPinterest;
