import React from 'react';
import PropTypes from 'prop-types';
import { withResources } from 'components/data/Resources';
import SnapchatSingleAd from './snapchat-single-ad';
import SnapchatStoryAd from './snapchat-story-ad';
import './../styles/main.scss';

class PreviewSnapchat extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        brandName: PropTypes.string,
        caption: PropTypes.string,
        headline: PropTypes.string,
        url: PropTypes.string,
        cta: PropTypes.string
    };

    static defaultProps = {
        adType: 'single',
        advertiser: {
            name: 'Cape',
            logo: ''
        }
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'single':
                component = <SnapchatSingleAd {...props} />;
                break;

            case 'story':
                component = <SnapchatStoryAd {...props} />;
                break;

            default:
                component = null;
        }

        return component;
    }

    render() {
        const { adType } = this.props;

        return <div className="social-preview__snapchat">{PreviewSnapchat.getChild(adType, this.props)}</div>;
    }
}

export default withResources(PreviewSnapchat, ['snapchat_cta']);
