import './../styles/main.scss';
import PropTypes from 'prop-types';
import React from 'react';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import DynamicAsset from 'components/assets/DynamicAsset';
import SnapchatSingleAd from './snapchat-single-ad';
import SnapchatStoryBackground from '../images/snapchat-story-background.png';
import iconCancel from '../images/cancel.svg';

class SnapchatStoryAd extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            view: 'tile',
            carouselIndex: 0,
            showClose: false
        };
        this.snapchatStoriesRef = React.createRef();
    }

    static propTypes = {
        adType: PropTypes.string,
        logo: PropTypes.string,
        brandName: PropTypes.string,
        headline: PropTypes.string,
        frames: PropTypes.array,
        tile: PropTypes.object
    };

    static defaultProps = {
        adType: 'story',
        tile: {
            headline: '',
            logo: '',
            asset: {}
        },
        frames: []
    };

    componentDidUpdate(prevProps) {
        if (prevProps.frameNr !== this.props.frameNr) {
            if (this.props.frameNr > 0) {
                this.swipeOnFrameNr(this.props.frameNr);
            }
        }
    }

    componentDidMount() {
        if (this.snapchatStoriesRef.current) {
            this.swipeOnFrameNr(this.props.frameNr);
        }
    }

    showTileView = () => {
        this.setState({ view: 'tile' });
    };

    showCreativesView = () => {
        this.setState({ view: 'creatives' });
    };

    handleHover = (move) => {
        if (move === 'enter') {
            this.setState({ showClose: true });
        } else {
            this.setState({ showClose: false });
        }
    };

    swipe = (direction) => {
        const element = this.snapchatStoriesRef.current;
        const distance = 320;
        if (direction === 'right') {
            element.scrollLeft += distance;
        } else {
            if (element.scrollLeft % distance === 0) {
                element.scrollLeft += -distance;
            } else {
                element.scrollLeft += -(element.scrollLeft % distance);
            }
        }

        setTimeout(() => {
            const index = element.scrollLeft / distance;

            this.setState({
                carouselIndex: Math.round(index)
            });
        }, 340);
    };

    swipeOnFrameNr = (frameNr) => {
        const element = this.snapchatStoriesRef.current;
        const distance = 320;
        this.setState(
            {
                carouselIndex: frameNr - 1,
                view: 'creatives'
            },
            () => (element.scrollLeft = (frameNr - 1) * distance)
        );
    };

    render() {
        const { brandName, headline, tile, frames = [], frameCount = 0, items } = this.props;
        const { carouselIndex, view, showClose } = this.state;
        let framesRestructured = [];

        // Framecount based items
        if (frameCount > 0) {
            for (let i = 1; i <= frameCount; i++) {
                framesRestructured.push(frames['frame' + i] ? frames['frame' + i] : {});
            }
        }
        // Support object based (
        else if (items && items.frame1) {
            framesRestructured = Object.values(items);
        }
        // Support array based (items)
        else if (Array.isArray(items)) {
            framesRestructured = items;
        }
        // Support object based
        else if (frames && frames.frame1) {
            framesRestructured = Object.values(frames);
        }
        // Support array based
        else if (Array.isArray(frames)) {
            framesRestructured = frames;
        }

        return (
            <div className="story">
                <div className="story__tile" onClick={this.showCreativesView} style={view === 'tile' ? { display: 'block' } : { display: 'none' }}>
                    <img className="story__tile__background" src={SnapchatStoryBackground} alt="snapchat-story-background" />
                    <div className="story__tile__dynamic-ad-wrapper">
                        <div className="story__tile__ad">
                            {tile && <DynamicAsset {...tile.asset} image={tile.backgroundImage} style={{ width: '100%', height: '100%' }} />}
                            <div className="story__tile__overlay-top"></div>
                            <div className="story__tile__logo">{tile.logo && <img src={tile.logo && tile.logo.url ? tile.logo.url : tile.logo} />}</div>
                            <div className="story__tile__headline">{tile.headline}</div>
                            <div className="story__tile__sponsored">Sponsored</div>
                            <div className="story__tile__overlay-bottom"></div>
                        </div>
                    </div>
                </div>

                <div
                    className="story__creatives"
                    style={view === 'creatives' ? { display: 'block' } : { display: 'none' }}
                    onMouseEnter={() => this.handleHover('enter')}
                    onMouseLeave={() => this.handleHover('leave')}>
                    {showClose && (
                        <div className="story__creatives__close-creative" onClick={this.showTileView}>
                            <img src={iconCancel} />
                        </div>
                    )}
                    <div className="story__creatives___stories-ad">
                        <div className="story__creatives__carousel" ref={this.snapchatStoriesRef}>
                            {framesRestructured.map(({ asset, cta, url, image, video }, index) => (
                                <div key={url + index} className="story__creatives__carousel-item">
                                    <SnapchatSingleAd
                                        {...this.props}
                                        index={index}
                                        brandName={brandName}
                                        headline={headline}
                                        asset={asset}
                                        image={image}
                                        video={video}
                                        cta={cta}
                                        url={url}
                                    />
                                </div>
                            ))}
                        </div>

                        {carouselIndex > 0 && (
                            <div className="story__creatives__arrow-left" onClick={() => this.swipe('left')}>
                                <ChevronLeft fontSize="large" className="story__creatives__arrow-icon" />
                            </div>
                        )}

                        {carouselIndex !== framesRestructured.length - 1 && framesRestructured.length > 1 && (
                            <div className="story__creatives__arrow-right" onClick={() => this.swipe('right')}>
                                <ChevronRight fontSize="large" className="story__creatives__arrow-icon" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default SnapchatStoryAd;
