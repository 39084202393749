import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import iconUpArrow from '../images/up-arrow.svg';
import iconMoreVertical from '../images/more_vertical.svg';
import './../styles/main.scss';

class SnapchatSingleAd extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            headline: PropTypes.string
        }),
        logo: PropTypes.string,
        caption: PropTypes.string,
        url: PropTypes.string,
        headline: PropTypes.string,
        cta: PropTypes.string,
        asset: PropTypes.shape({
            type: PropTypes.string,
            url: PropTypes.string
        })
    };

    static defaultProps = {
        adType: 'single'
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    transformCaption = (caption) => {
        if (caption) {
            const splitted = caption.split(' ');

            return (
                <div>
                    {splitted.map((word, index) => (
                        <span key={index + word}>{word} </span>
                    ))}
                </div>
            );
        }
    };

    getAsset = () => {
        const { assetComponents, asset, image, video, index } = this.props;

        // existing prop
        if (assetComponents) {
            if (index) {
                return assetComponents[index] && assetComponents[index][0] ? assetComponents[index][0] : <React.Fragment />;
            } else if (assetComponents[0] && assetComponents[0][0]) {
                return assetComponents[0][0];
            } else {
                return assetComponents[0];
            }
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1080}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 1920}
                    width={320}
                />
            );
        }
    };

    render() {
        const { brandName, headline, caption, snapchat_cta } = this.props;
        let { cta } = this.props;

        if (!cta) {
            cta = 'MORE';
        }

        return (
            <div>
                <div className="single-ad">
                    <div className="single-ad__asset-wrapper">{this.getAsset()}</div>

                    <div className="single-ad__advertiser">
                        <div className="single-ad__advertiser__data">
                            <div className="single-ad__advertiser__brandName">{brandName}</div>
                            <div className="single-ad__advertiser__headline">{headline}</div>
                        </div>
                        <div className="single-ad__advertiser__icon-more">
                            <img className="single-ad__advertiser__icon-more-vertical" src={iconMoreVertical} alt={'icon-more-vertical'} />
                        </div>
                    </div>

                    <div className="single-ad__caption">{caption !== null && caption !== null && caption !== '' && this.transformCaption(caption)}</div>

                    {cta && cta !== '' && (
                        <div target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', border: '1px solid red' }}>
                            <div className="single-ad__swipeup">
                                <img className="single-ad__swipeup__icon-swipeup" src={iconUpArrow} alt={'icon-arrow-up'} />
                                <div className="single-ad__swipeup__cta">{snapchat_cta[cta]}</div>
                            </div>
                        </div>
                    )}

                    <div className="single-ad__shadow"></div>
                </div>
            </div>
        );
    }
}

export default SnapchatSingleAd;
