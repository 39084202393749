import React from 'react';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { withResources } from 'components/data/Resources';
import FooterDiscover from '../images/footer-discover.svg';
import FooterInbox from '../images/footer-inbox.svg';
import FooterMe from '../images/footer-me.svg';
import FooterStart from '../images/footer-start.svg';
import FooterTikTokIcon from '../images/footer-tiktok-icon.svg';
import SidebarLikes from '../images/sidebar-likes.svg';
import SidebarMessages from '../images/sidebar-messages.svg';
import SidebarShare from '../images/sidebar-share.svg';
import TikTokSingle from './tiktok-single';
import './../styles/main.scss';

const reactStringReplace = require('react-string-replace');

class PreviewTikTok extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showFooter: true
        };
    }

    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        headline: PropTypes.string,
        displayUrl: PropTypes.string
    };

    static defaultProps = {
        adType: 'videoPost',
        advertiser: {
            name: 'Cape',
            logo: ''
        }
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'videoAd':
                component = <TikTokSingle {...props} />;
                break;

            case 'videoPost':
                component = <TikTokSingle {...props} />;
                break;

            default:
                component = null;
        }

        return component;
    }

    showFooter = () => {
        this.setState({ showFooter: true });
    };

    hideFooter = () => {
        this.setState({ showFooter: false });
    };

    render() {
        const { adType, advertiser, displayName, adText, cta, tiktok_cta } = this.props;
        const { showFooter } = this.state;

        return (
            <div className="social-preview__tiktok">
                <div className="social-preview__tiktok__wrapper" onMouseEnter={() => this.hideFooter()} onMouseLeave={() => this.showFooter()}>
                    <div className="social-preview__tiktok__wrapper__asset">
                        {PreviewTikTok.getChild(adType, this.props)}
                        <div className="social-preview__tiktok__wrapper__asset__overlay-top"></div>
                        {showFooter && <div className="social-preview__tiktok__wrapper__asset__overlay-bottom"></div>}
                    </div>
                    <div className="social-preview__tiktok__wrapper__sidebar">
                        <div className="social-preview__tiktok__wrapper__sidebar__profile" style={{ backgroundImage: `url(${advertiser.logo})` }}></div>
                        <div className="social-preview__tiktok__wrapper__sidebar__icon">
                            <img src={SidebarLikes} />
                            62.1k
                        </div>
                        <div className="social-preview__tiktok__wrapper__sidebar__icon">
                            <img src={SidebarMessages} />
                            511
                        </div>
                        <div className="social-preview__tiktok__wrapper__sidebar__icon">
                            <img src={SidebarShare} />
                            617
                        </div>
                    </div>
                    <div className="social-preview__tiktok__wrapper__nav">
                        <span style={{ fontSize: 16, opacity: 0.8, marginRight: 8 }}>Following</span>
                        <span style={{ opacity: 0.8, fontSize: 10, marginRight: 8 }}>|</span>
                        <span>
                            For You<b></b>
                        </span>
                    </div>
                    <div className="social-preview__tiktok__wrapper__copy">
                        <div className="social-preview__tiktok__wrapper__copy__username">@{displayName}</div>
                        <div className="social-preview__tiktok__wrapper__copy__description">
                            {reactStringReplace(adText, /#(\w+)/g, (match, i) => (
                                <span key={i} className="social-preview__tiktok__wrapper__copy__description__hashtag">
                                    #{match}
                                </span>
                            ))}
                        </div>
                        <div className="social-preview__tiktok__wrapper__copy__cta">
                            {tiktok_cta[cta]} <ChevronRightIcon style={{ fontSize: 16 }} />
                        </div>
                    </div>
                    {showFooter && (
                        <div className="social-preview__tiktok__wrapper__footer">
                            <div className="social-preview__tiktok__wrapper__footer__icon">
                                <img src={FooterStart} />
                                Home
                            </div>
                            <div className="social-preview__tiktok__wrapper__footer__icon">
                                <img src={FooterDiscover} />
                                Discover
                            </div>
                            <div className="social-preview__tiktok__wrapper__footer__icon">
                                <img src={FooterTikTokIcon} style={{ marginBottom: 8 }} />
                            </div>
                            <div className="social-preview__tiktok__wrapper__footer__icon">
                                <img src={FooterInbox} />
                                Inbox
                            </div>
                            <div className="social-preview__tiktok__wrapper__footer__icon">
                                <img src={FooterMe} />
                                Me
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withResources(PreviewTikTok, ['tiktok_cta']);
