import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';

class TwitterSingle extends React.Component {
    static propTypes = {
        asset: PropTypes.object
    };

    getAsset = () => {
        const { assetComponents, asset, image, video } = this.props;

        // Existing prop
        if (assetComponents) {
            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset.frameWidth ? asset.frameWidth : 1200}
                    frameHeight={asset.frameHeight ? asset.frameHeight : 620}
                    width={240}
                    style={{ borderRadius: 16 }}
                />
            );
        }
    };

    render() {
        const { adType, title, displayUrl } = this.props;

        return (
            <div className="single">
                {(adType === 'tweet' || adType === 'promotedAsset') && (
                    <div className="single__promoted">
                        <div className="single__promoted__asset-wrapper">{this.getAsset()}</div>
                    </div>
                )}
                {adType === 'websiteCard' && (
                    <div className="single__card">
                        <div className="single__card__asset-wrapper">{this.getAsset()}</div>
                        <div className="single__card__copy">
                            <a href={displayUrl} target="_blank" style={{ textDecoration: 'none' }}>
                                <div className="single__card__copy__title">{title}</div>
                                <div className="single__card__copy__displayUrl">{displayUrl}</div>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default TwitterSingle;
