import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import iconMore from '../images/icon_more.png';

class PinterestSingle extends React.Component {
    static propTypes = {
        asset: PropTypes.object,
        title: PropTypes.string,
        description: PropTypes.string
    };

    static defaultProps = {};

    getAsset = () => {
        const { assetComponents, asset, image, video } = this.props;

        // existing prop
        if (assetComponents) {
            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1000}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 1500}
                    width={320}
                    style={{ borderRadius: 16 }}
                />
            );
        }
    };

    render() {
        const { title, description, advertiser, adType } = this.props;

        return (
            <div className="single">
                <div className="single__asset-wrapper">{this.getAsset()}</div>
                {adType === 'sponsoredPin' && (
                    <div>
                        {title && <div className="single__title">{title}</div>}
                        {!title && <div className="single__title">{description}</div>}
                        <div className="single__advertiser">
                            <div className="single__advertiser__logo" style={{ backgroundImage: `url(${advertiser.logo})` }} />
                            <div className="single__advertiser__text">
                                <div className="single__advertiser__sponsored">Promoted by</div>

                                <div className="single__advertiser__name">{advertiser.name}</div>
                            </div>

                            <div className="single__advertiser__more">
                                <img src={iconMore} alt="icon-more" />
                            </div>
                        </div>
                    </div>
                )}
                {adType === 'pin' && (
                    <div className="single__advertiser__more">
                        <img src={iconMore} alt="icon-more" />
                    </div>
                )}
            </div>
        );
    }
}

export default PinterestSingle;
