import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';

class TwitterMultiImage extends React.Component {
    static propTypes = {
        asset: PropTypes.object
    };
    static defaultProps = {};

    getAsset = (index, frame) => {
        const { assetComponents } = this.props;
        let asset, image, video;

        if (frame) {
            asset = frame.asset;
            image = frame.image;
            video = frame.video;
        }
        if (assetComponents) {
            return assetComponents[index] && assetComponents[index][0] ? assetComponents[index][0] : <React.Fragment />;
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset.frameWidth ? asset.frameWidth : 1200}
                    frameHeight={asset.frameHeight ? asset.frameHeight : 620}
                    width={240}
                />
            );
        }
    };

    render() {
        const { adType, frames = [], items } = this.props;
        let { frameCount } = this.props;
        let framesRestructured = [];

        // Framecount based items
        if (frameCount > 0) {
            for (let i = 1; i <= frameCount; i++) {
                framesRestructured.push(frames['frame' + i] ? frames['frame' + i] : {});
            }
        }
        // Support object based (
        else if (items && items.frame1) {
            framesRestructured = Object.values(items);
        }
        // Support array based (items)
        else if (Array.isArray(items)) {
            framesRestructured = items;
        }
        // Support object based
        else if (frames && frames.frame1) {
            framesRestructured = Object.values(frames);
        }
        // Support array based
        else if (Array.isArray(frames)) {
            framesRestructured = frames;
        }

        // For the old editor: Remove from framesRestructured if there is no image url
        framesRestructured = framesRestructured.filter((frame) => frame.asset && frame.asset.url);

        if (framesRestructured.length > 0) {
            frameCount = framesRestructured.length;
        }

        return (
            <div className="multi-image">
                {adType === 'multiImage' && (
                    <div className="single__multi-image">
                        {frameCount === 1 && <div className="single__multi-image__asset-wrapper">{this.getAsset(0, framesRestructured[0])}</div>}
                        {frameCount === 2 && (
                            <div className="single__multi-image__two">
                                <div className="single__multi-image__two__1 single__multi-image__asset-wrapper">{this.getAsset(0, framesRestructured[0])}</div>
                                <div className="single__multi-image__two__2 single__multi-image__asset-wrapper">{this.getAsset(1, framesRestructured[1])}</div>
                            </div>
                        )}
                        {frameCount === 3 && (
                            <div className="single__multi-image__three">
                                <div className="single__multi-image__three__1 single__multi-image__asset-wrapper">
                                    {this.getAsset(0, framesRestructured[0])}
                                </div>
                                <div className="single__multi-image__three__wrapper">
                                    <div className="single__multi-image__three__2 single__multi-image__asset-wrapper">
                                        {this.getAsset(1, framesRestructured[1])}
                                    </div>
                                    <div className="single__multi-image__three__3 single__multi-image__asset-wrapper">
                                        {this.getAsset(2, framesRestructured[2])}
                                    </div>
                                </div>
                            </div>
                        )}
                        {frameCount >= 4 && (
                            <div className="single__multi-image__four">
                                <div className="single__multi-image__four__line-one">
                                    <div className="single__multi-image__four__1 single__multi-image__asset-wrapper">
                                        {this.getAsset(0, framesRestructured[0])}
                                    </div>
                                    <div className="single__multi-image__four__2 single__multi-image__asset-wrapper">
                                        {this.getAsset(1, framesRestructured[1])}
                                    </div>
                                </div>
                                <div className="single__multi-image__four__line-two">
                                    <div className="single__multi-image__four__3 single__multi-image__asset-wrapper">
                                        {this.getAsset(2, framesRestructured[2])}
                                    </div>
                                    <div className="single__multi-image__four__4 single__multi-image__asset-wrapper">
                                        {this.getAsset(3, framesRestructured[3])}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default TwitterMultiImage;
