import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import './../styles/main.scss';

/**
 * TikTok video
 * This displays a preview of the TikTok organic video
 */
class TikTokSingle extends React.Component {
    static propTypes = {
        adType: PropTypes.string
    };

    static defaultProps = {
        adType: 'videoAd'
    };

    getAsset = () => {
        const { assetComponents, asset, image, video } = this.props;

        // Existing prop
        if (assetComponents && assetComponents[0] && assetComponents[0][0]) {
            return assetComponents[0][0];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1080}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 1920}
                    width={320}
                    controls={false}
                />
            );
        }
    };

    render() {
        return (
            <div className="social-preview__tiktok__video">
                <div className="social-preview__tiktok__video__asset-wrapper">{this.getAsset()}</div>
            </div>
        );
    }
}

export default TikTokSingle;
