import React from 'react';
import PropTypes from 'prop-types';
import TwitterSingle from './twitter-single';
import TwitterMultiImage from './twitter-multi-image';
import iconArrowDown from '../images/icon_arrow_down.png';
import iconPromoted from '../images/icon_promoted.png';
import iconBar from '../images/icon_bar.png';
import './../styles/main.scss';

class PreviewTwitter extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        text: PropTypes.string
    };

    static defaultProps = {
        adType: 'promotedAsset',
        advertiser: {
            name: 'Cape',
            logo: ''
        }
    };

    transformText = () => {
        const { text } = this.props;

        if (text) {
            const textArray = text.trim().split(' ');
            const wordsArray = [];

            textArray.forEach((word) => {
                if (word[0] !== '#' && word[0] !== '@') {
                    wordsArray.push({ word, hashtag: false });
                } else {
                    wordsArray.push({ word, hashtag: true });
                }
            });

            return (
                <div>
                    {wordsArray.map((word, index) => {
                        if (word.hashtag) {
                            return (
                                <span key={word.word + index} className="hashtag">
                                    {' '}
                                    {word.word}
                                </span>
                            );
                        } else {
                            return <span key={word.word + index}> {word.word}</span>;
                        }
                    })}
                </div>
            );
        }
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'promotedAsset':
                component = <TwitterSingle {...props} />;
                break;

            case 'websiteCard':
                component = <TwitterSingle {...props} />;
                break;

            case 'multiImage':
                component = <TwitterMultiImage {...props} />;
                break;

            case 'tweet':
                component = <TwitterSingle {...props} />;
                break;

            default:
                component = null;
        }

        return component;
    }

    render() {
        const { adType, advertiser, text } = this.props;

        return (
            <div className="social-preview__twitter">
                <div className="social-preview__twitter__wrapper">
                    <div className="social-preview__twitter__wrapper__logo-wrapper">
                        <div className="social-preview__twitter__wrapper__logo" style={{ backgroundImage: `url(${advertiser.logo})` }} />
                    </div>

                    <div className="social-preview__twitter__wrapper__info">
                        <div className="social-preview__twitter__wrapper__info__advertiser">
                            <div className="social-preview__twitter__wrapper__info__advertiser__text">
                                <div className="social-preview__twitter__wrapper__info__advertiser__name">{advertiser.name}</div>
                                <div className="social-preview__twitter__wrapper__info__advertiser__tag"> @{advertiser.tag}</div>
                                {adType === 'tweet' && <div className="social-preview__twitter__wrapper__info__advertiser__date">· 7m</div>}
                            </div>
                            <div className="social-preview__twitter__wrapper__info__advertiser__icon">
                                <img src={iconArrowDown} alt="icon_arrow_down" />
                            </div>
                        </div>

                        {text && <div className="social-preview__twitter__wrapper__info__text">{this.transformText()}</div>}

                        {adType !== 'promotedText' && PreviewTwitter.getChild(adType, this.props)}

                        <div className="social-preview__twitter__wrapper__info__icon-bar">
                            <img src={iconBar} alt="icon-bar" />
                        </div>

                        {adType !== 'tweet' && adType !== 'multiImage' && (
                            <div className="social-preview__twitter__wrapper__info__promoted">
                                <img src={iconPromoted} alt="icon-promoted" />
                                <div className="social-preview__twitter__wrapper__info__promoted-text">Promoted</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default PreviewTwitter;
